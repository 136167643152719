/* General container for all components */
.inputContainer {
  margin-bottom: 15px;
  padding: 13px;
  border: 1px solid #cbd6e2;
  border-radius: 5px;
  background-color: #f5f8fa;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  transition: box-shadow 0.3s ease;
  }
  
  .inputContainer:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Label styling for clarity */
  .inputLabel {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #3a4e61 !important;
    margin-bottom: 5px;
  }
  
  /* Radio and checkbox group styling */
  .optionGroup {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
  }
  
  .radioOption,
  .checkboxOption {
    display: flex;
    align-items: center;
    
    color: #33475b;
  }
  
  /* Select (dropdown) styling */
  .dropdownContainer {
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;

  }
  .range_container {
    width: 50%;
    flex: 0 0 auto;
}
  .dropdownLabel {
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-bottom: 4px !important;
    font-family: 'Inter' !important;
    color: #33475b !important ;
  }
  
  .selectInput {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: #333;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  .main_popover div {max-width: 300px !important;     min-width: 130px; }
  
  /* Text input styling for min and max inputs */
  .textInput  input {
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    background-color: white;
    font-size: 13px;
  }
  
  /* Dropdown menu styling */
  .dropdownMenu {
    z-index: 1000000; /* Ensures visibility in chat overlays */
    width: 100%;
    font-size: 13px;
  }
  

  .inputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  

  .selectInput {
    border: 1px solid #ccc;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    background-color: white;
    font-size: 13px;
    position: relative;
  }
  .selectInput:after {
    content: '';
    background-image: url(https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/arrow_down_input.svg);
    width: 18px;
    height: 18px;
    position: absolute;
    right: 5px;
    z-index: 9999999999;
    top: 9px;
    background-size: contain;
}
  .menuPaper {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    max-height: 200px;
    width: 285px !important;
    overflow-y: auto;
    width: 100%;
    z-index: 99999999; /* Ensures the dropdown appears on top */
  }

  .main_popover ::-webkit-scrollbar {
    width: 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
  
  /* Track */
  .popover_lundiniom.main_popover ::-webkit-scrollbar-thumb {background: #545659;}
  .popover_btc.main_popover ::-webkit-scrollbar-thumb {background: #3c6e95;}
  .popover_talbies.main_popover ::-webkit-scrollbar-thumb {background: #B09981;}
  .popover_charlessinclair.main_popover ::-webkit-scrollbar-thumb {background: #c7b354;}
  .popover_denhan.main_popover ::-webkit-scrollbar-thumb, .popover_daniels.main_popover ::-webkit-scrollbar-thumb, .popover_magicbrick.main_popover ::-webkit-scrollbar-thumb  {background: #c0c0c0;}
  
  .main_popover ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  
  /* Handle */
  .main_popover ::-webkit-scrollbar-thumb {
    background: #b37cfb;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
.pill_check {
   font-size: 11px !important;
    font-weight: 500 !important;
    height: 26px !important;
}
.subText {
  font-size: 13px;
  margin-bottom: 10px;
  color: #7f7979;
  font-weight: 500;
}
.sub_daniels {
  color:#f9f9f9 ;
}
.sub_magicbrick, .sub_lundiniom{
  color:#343434 ;
}
  
  .checkboxOption span, .radioOption span {padding-top: 3px; padding-bottom: 2px; font-size: 13px !important; font-family: inter;}
  .input_daniels {background-color: #090B0C;}

  .input_daniels .inputLabel {
    color: #ffffff !important;
  }
  .input_daniels .dropdownLabel {
   
    color: #fff !important;
  }
  .input_daniels .textInput input, .input_daniels  .selectInput {
    background-color: #272727;
    color: #fff;
  }

  .input_daniels .checkboxOption span, .input_daniels .radioOption span {
    color: #fff;
  }
  .input_magicbrick, .input_lundiniom {background-color: rgb(255, 204, 0);}
  .input_magicbrick .inputLabel, .input_magicbrick .dropdownLabel, .input_lundiniom .inputLabel, .input_lundiniom .dropdownLabel {
    color: #121212 !important;
  }
  .input_magicbrick .textInput input, .input_magicbrick .selectInput, .input_lundiniom .textInput input, .input_lundiniom .selectInput {
    background-color: #121212;
    color: #fff;
  }
  .input_magicbrick .checkboxOption span, .input_magicbrick .radioOption span, .input_lundiniom .checkboxOption span, .input_lundiniom .radioOption span {
    color: #121212;
  }

