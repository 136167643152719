.bot_tip {
    background-color: #212121;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 4px;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    left: 50%;
    /* margin-left: -60px; */
    font-size: 11px;
    min-width: 53px;
}
.bot_tip:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent;
}