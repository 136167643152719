/* PropertyValuation.module.css */
.container {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  background: #fff;
}
  
.header {
  /* margin-bottom: 5px;
  padding-bottom: 5px; */
}
  
  .title {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    font-family: 'Inter';
  }
  
  .value {
    font-size: 30px;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 0px;
    font-family: 'Inter';
    font-weight: 800;
    color: #322744;
  }
  
  .value span {
    font-size: 18px;
    font-weight: bold;
    margin: 6px 0;
    font-family: 'Inter';
    font-weight: 800;
    color: #322744;
  }
  
  .confidence {
    background-color: #58B743;
    color: white;
    border: none;
    padding: 3px 16px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 600;
    margin-left: 10px;
  }
  
  .history {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    margin-top: 0px;
    padding-top: 15px;
  }
  
  .historyTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 5px 0;
    font-family: 'Inter';
    font-weight: 600;
    display: flex
;
    align-items: center;
    gap: 5px;
    
  }
  
  .address {

    font-size: 12px;
    margin: 0 0 16px 0;
    font-weight: 700;
    color: #848383;
  }
  
  .historyItem {
    padding-bottom: 3px;
    border-bottom: 1px solid rgba(0,0,0,0.08);
    margin-bottom: 10px;
  }
  .historyItem:last-child {border: unset;padding-bottom: unset;}
  
  .soldOn {
    font-size: 14px;
    color: #E6492D;
    margin: 0 0 4px 0;
    font-weight: 600;
  }
  
  .price {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 4px 0;
    font-family: 'Inter';
    color: #2A2A2A;
  }
  .history_date {color: #2A2A2A;font-weight: 600;}
  
  .details {
    font-size: 13px;
    margin-top: 2px;
    font-weight: 500;
  }
  .history_wrap {
    display: flex;
    justify-content: space-between;
  }
  .details span {
    color: #5D5D5D;
 }
 .history_seperator {
    color: #b9b9b9 !important;
 }

 .container.container_lundiniom, .container.container_magicbrick {
    border: 1px solid  #000;
    background: #ffc800;
    }
    
    .container.container_lundiniom .address, .container.container_magicbrick .address {
        color: #414141;
    }
    .container.container_lundiniom .details span, .container.container_magicbrick .details span {
        color: #282828;
    }
    .container.container_lundiniom .history_seperator, .container.container_magicbrick .history_seperator {
        color: #4d4c4c !important;
    }


    @media screen and (max-width:300px) {
    .history_wrap {
        display: block;
    }
}